<template>
  <div class="card">
    <div class="service-details-wrapper">
      <div class="service-details">
        <h6 class="service-label">Ticket Title</h6>
        <h6 class="service-text">
          {{ getValueFromSource(service, "title") | truncate(28) }}
        </h6>
      </div>

      <div class="service-details">
        <h6 class="service-label">Price</h6>
        <h6 class="service-text price-wrapper">
          {{ formatAmount(getValueFromSource(service, "price")) }}
        </h6>
      </div>
    </div>

    <p class="text-truncate text-muted mx-2">
      {{ getValueFromSource(service, "description") }}
    </p>

    <div class="service-details-wrapper">
      <div style="display: flex; flex-flow: row wrap">
        <div
          class="service-details cursor-pointer"
          style="margin-right: 10px"
          @click="onEditServiceClicked"
          v-if="canUpdateService"
        >
          <span
            style="color: green; margin-right: 5px"
            class="fe fe-edit"
          ></span>
          <span class="" style="color: black">Edit</span>
        </div>

        <div
          class="service-details cursor-pointer"
          v-if="canRemoveService"
          @click="onEditRemoveClicked"
        >
          <span
            style="color: red; margin-right: 5px"
            class="fe fe-x-circle"
          ></span>
          <span class="" style="color: black">Delete</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { get } from "lodash";
import { formatAmount } from "@/helpers";
import { Modal } from "ant-design-vue";

export default {
  props: {
    service: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    ...mapGetters("tourOperator", ["tourOperatorMedia", "services"]),
    permissions() {
      return get(this.currentUser, "permissions", {});
    },
    canUpdateService() {
      return get(this.permissions, "can_update_service", false);
    },
    canRemoveService() {
      return get(this.permissions, "can_remove_service", false);
    },
  },
  methods: {
    formatAmount(amount) {
      return formatAmount(amount);
    },
    getValueFromSource(source, path, defaultValue = "") {
      return get(source, path, defaultValue);
    },
    onEditServiceClicked() {
      this.$emit("editService", this.service);
    },
    onEditRemoveClicked() {
      const that = this;
      Modal.confirm({
        title: "Confirm Delete",
        content: "Are you sure you want to delete this service?",
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk() {
          that.$emit("removeService", that.service);
        },
        onCancel() {
          // console.log("Cancel");
        },
      });
    },
  },
};
</script>

<style scoped>
.service-details-wrapper {
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.service-label {
  font-size: 14px;
  color: gray;
  margin: 0;
}
.service-text {
  font-size: 18px;
  margin: 0;
}
.price-wrapper {
  background-color: green;
  color: white;
  border-radius: 5px;
  padding: 5px;
  font-size: 12px;
}
.service-type-wrapper {
  background-color: gray;
  color: black;
  border-radius: 5px;
  padding: 5px;
  font-size: 12px;
}

.outer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: inherit;
  width: inherit;
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.2);
  height: 120px;
}

.card {
  height: 150px;
  max-height: 150px;
  width: 300px;
  /* max-width: 350px; */
  color: white;
  background: white;
  /* border-width: 2px; */
  /* border-color: rgba(0, 0, 0, 0.2); */

  transition: 0.3s;
  border-radius: 5px;
  /* cursor: pointer; */
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.cursor-pointer {
  cursor: pointer;
}
</style>
