<template>
  <div>
    <a-modal
      v-model="visible"
      :title="modalTitle"
      @ok="saveService"
      okText="Save"
    >
      <a-spin :spinning="loading">
        <a-form :form="form">
          <div class="row">
            <div class="col-md-12">
              <a-form-item label="Title">
                <a-input
                  placeholder="Title"
                  auto-size
                  v-decorator="[
                    'title',
                    {
                      rules: [
                        { required: true, message: 'Title is required!' },
                      ],
                      initialValue: service.title,
                    },
                  ]"
                />
              </a-form-item>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <a-form-item label="Description">
                <a-textarea
                  placeholder="Description"
                  :rows="5"
                  v-decorator="[
                    'description',
                    {
                      rules: [
                        { required: true, message: 'Description is required!' },
                      ],
                      initialValue: service.description,
                    },
                  ]"
                />
              </a-form-item>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <a-form-item label="Price">
                <a-input-number
                  :formatter="formattedPrice"
                  v-decorator="[
                    'price',
                    {
                      rules: [
                        { required: true, message: 'Price is required!' },
                      ],
                      initialValue: service.price,
                    },
                  ]"
                />
              </a-form-item>
            </div>
          </div>
        </a-form>
      </a-spin>
    </a-modal>
  </div>
</template>
<script>
import cloneDeep from "lodash/cloneDeep";
import ProfileMixin from "@/mixins/Profile";

export default {
  mixins: [ProfileMixin],
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    defaultService: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      visible: false,

      service: {
        title: "",
        description: "",
        price: "",
      },
      // featuredImage: null,
      form: this.$form.createForm(this, { name: "addService" }),
    };
  },
  watch: {
    showModal: {
      handler(show) {
        this.visible = show;
      },
      immediate: true,
    },
    visible(show) {
      this.$emit("closeModal", show);
    },
  },
  computed: {
    isEdit() {
      return !!Object.keys(this.defaultService).length;
    },
    modalTitle() {
      return `${this.isEdit ? "Edit" : "Add"} Service`;
    },
  },
  mounted() {
    if (this.isEdit) {
      this.$nextTick(() => {
        const { title, description, price } = this.defaultService;
        this.form.setFieldsValue({
          title,
          description,
          price,
        });
      });
      this.service = cloneDeep(this.defaultService);
    }
  },
  methods: {
    saveService(e) {
      this.form.validateFields(async (err, values) => {
        if (err) {
          this.$notification.error({ message: "Please correct form errors." });
          return false;
        }
        this.addOrUpdateService(values);
      });
    },
    async addOrUpdateService(values) {
      try {
        this.loading = true;

        const formData = new FormData();

        const { _id } = this.service;

        const update_payload = {
          ...values,
        };

        if (_id) {
          update_payload._id = _id;
        }

        Object.keys(update_payload).forEach((key) => {
          formData.append(key, update_payload[key]);
        });

        await this.updateProfileService(formData);

        this.visible = false;
      } catch (error) {
        console.log("save or update error", error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
