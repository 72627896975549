<template>
  <div>
    <div class="service-header">
      <div class="font-size-36 font-weight-bold text-dark mb-4">Services</div>
      <a-button type="primary" @click="addService" v-if="canAddService"
        >Add new service</a-button
      >
    </div>

    <div class="row">
      <div v-if="services.length">
        <div
          class="flex-wrapper"
          v-if="allowedForGenericServiceUpdate.includes(entity_type)"
        >
          <shared-service-card-with-featured-image
            v-for="(service, index) of services"
            class="entity-card"
            :entity_type="entity_type"
            :key="index"
            :service="service"
            @editService="editService($event)"
            @removeService="deleteService($event)"
          />
        </div>

        <div class="flex-wrapper" v-else-if="entity_type === 'tour_sight'">
          <tour-sight-service-card
            v-for="(service, index) of services"
            class="entity-card"
            :entity_type="entity_type"
            :key="index"
            :service="service"
            @editService="editService($event)"
            @removeService="deleteService($event)"
          />
        </div>

        <div class="flex-wrapper" v-else-if="entity_type === 'food_and_drinks'">
          <shared-service-card-with-featured-images
            class="entity-card"
            v-for="(service, index) of services"
            :key="index"
            :service="service"
            service-title="Food Item"
            @editService="editService($event)"
            @removeService="deleteService($event)"
          />
        </div>

        <div class="flex-wrapper" v-else-if="entity_type === 'transport'">
          <shared-service-card-with-featured-images
            class="entity-card"
            v-for="(service, index) of services"
            :key="index"
            :service="service"
            service-title="Vehicle Name"
            @editService="editService($event)"
            @removeService="deleteService($event)"
          />
        </div>

        <div class="flex-wrapper" v-else-if="entity_type === 'events'">
          <shared-service-card-with-featured-images
            class="entity-card"
            v-for="(service, index) of services"
            :key="index"
            :service="service"
            service-title="Event Name"
            @editService="editService($event)"
            @removeService="deleteService($event)"
          />
        </div>
      </div>

      <div v-else class="text-center">
        <h4>No Services found</h4>
      </div>
    </div>

    <generic-service-modal
      v-if="showModal && allowedForGenericServiceUpdate.includes(entity_type)"
      :defaultService="defaultService"
      :showModal="showModal"
      @closeModal="closeModal"
    />

    <tour-sight-service-modal
      v-if="showModal && entity_type === 'tour_sight'"
      :defaultService="defaultService"
      :showModal="showModal"
      @closeModal="closeModal"
    />

    <food-and-drinks-service-modal
      v-if="showModal && entity_type === 'food_and_drinks'"
      :defaultService="defaultService"
      :showModal="showModal"
      @closeModal="closeModal"
    />

    <transport-rental-service-modal
      v-if="showModal && entity_type === 'transport'"
      :defaultService="defaultService"
      :showModal="showModal"
      @closeModal="closeModal"
    />

    <events-service-modal
      v-if="showModal && entity_type === 'events'"
      :defaultService="defaultService"
      :showModal="showModal"
      @closeModal="closeModal"
    />
  </div>
</template>
<script>
import SharedServiceCardWithFeaturedImage from "@/components/services/SharedServiceCardWithFeaturedImage.vue";
import TourSightServiceCard from "@/components/services/TourSightServiceCard.vue";
import SharedServiceCardWithFeaturedImages from "@/components/services/SharedServiceCardWithFeaturedImages.vue";

import GenericServiceModal from "@/components/services/GenericServiceModal.vue";
import TourSightServiceModal from "@/components/services/TourSightServiceModal.vue";
import FoodAndDrinksServiceModal from "@/components/services/FoodAndDrinksServiceModal.vue";
import TransportRentalServiceModal from "@/components/services/TransportRentalServiceModal.vue";
import EventsServiceModal from "@/components/services/EventsServiceModal.vue";

import ProfileMixin from "@/mixins/Profile";
import { mapGetters } from "vuex";
import { get } from "lodash";

export default {
  mixins: [ProfileMixin],
  components: {
    GenericServiceModal,
    TourSightServiceModal,
    FoodAndDrinksServiceModal,

    TourSightServiceCard,
    SharedServiceCardWithFeaturedImages,
    SharedServiceCardWithFeaturedImage,
    TransportRentalServiceModal,
    EventsServiceModal,
  },
  props: {
    entity_type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      defaultService: {},
      showModal: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["currentUserType", "currentUser"]),
    services() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.$store.getters[`${this.getUserAccountProfileModule}/services`]
        .reverse()
        .filter((service) => service.status === "active");
    },
    permissions() {
      return this.currentUser ? this.currentUser.permissions : {};
    },
    canAddService() {
      return get(this.permissions, "can_add_service", false);
    },
    canUpdateService() {
      return get(this.permissions, "can_update_service", false);
    },
    canRemoveService() {
      return get(this.permissions, "can_remove_service", false);
    },
    allowedForGenericServiceUpdate() {
      return ["hotel_and_accommodations"];
    },
  },
  methods: {
    addService() {
      this.showModal = true;
    },
    closeModal() {
      this.defaultService = {};
      this.showModal = false;
    },
    editService(service) {
      this.defaultService = service;
      this.addService();
    },
    async deleteService(service) {
      await this.deleteProfileService(service._id);
      this.closeModal();
    },
  },
};
</script>

<style scoped>
.editable-row-operations a {
  margin-right: 8px;
}
.service-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flex-wrapper {
  display: flex;
  flex-direction: row;
  align-self: auto;
  flex-wrap: wrap;
}
.entity-card {
  margin-right: 25px;
}
</style>
