<template>
  <a-modal
    v-model="visible"
    :title="modalTitle"
    okText="Save"
    @ok="handleSubmit"
  >
    <a-spin tip="Please wait..." :spinning="loading">
      <a-form :form="form">
        <div class="row">
          <div class="col-md-12">
            <a-form-item label="Title">
              <a-input
                placeholder="Title"
                auto-size
                v-decorator="[
                  'title',
                  {
                    rules: [{ required: true, message: 'Title is required!' }],
                    initialValue: service.title,
                  },
                ]"
              />
            </a-form-item>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <a-form-item label="Description">
              <a-textarea
                placeholder="Description"
                :rows="5"
                v-decorator="[
                  'description',
                  {
                    rules: [
                      { required: true, message: 'Description is required!' },
                    ],
                    initialValue: service.description,
                  },
                ]"
              />
            </a-form-item>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <a-form-item label="Price">
              <a-input-number
                :formatter="formattedPrice"
                v-decorator="[
                  'price',
                  {
                    rules: [{ required: true, message: 'Price is required!' }],
                    initialValue: service.price,
                  },
                ]"
              />
            </a-form-item>
          </div>
        </div>

        <p class="text-info">You can upload max 3 images for this service.</p>
        <a-upload
          multiple
          list-type="picture-card"
          accept="image/*"
          :defaultFileList="featuredImageData"
          :before-upload="beforeFeaturedImageUpload"
          :remove="handleRemove"
          :fileList="fileList"
          @preview="handlePreview"
          @change="handleChange"
        >
          <div v-if="fileList.length < 3">
            <a-icon type="plus" />
            <div class="ant-upload-text">Upload Featured Images</div>
          </div>
        </a-upload>

        <a-modal
          :visible="previewVisible"
          :footer="null"
          @cancel="handleCancel"
        >
          <img alt="example" style="width: 100%" :src="previewImage" />
        </a-modal>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { cloneDeep } from "lodash";
import ProfileMixin from "@/mixins/Profile";
import { mapActions } from "vuex";
import validator from "validator";

import { DELETE_FOOD_AND_BEVERAGES_SERVICE_FEATURED_MEDIA } from "@/store/actions";

export default {
  mixins: [ProfileMixin],
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    defaultService: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      visible: false,

      previewVisible: false,
      previewImage: "",

      service: {
        title: "",
        description: "",
        price: "",
      },

      fileList: [],
      featuredImages: [],

      // for validation
      form: this.$form.createForm(this, { name: "addService" }),
    };
  },
  computed: {
    featuredImageData() {
      const { featured_images } = this.service;
      if (featured_images && featured_images.length) {
        return featured_images.map((featured_image) => {
          const { _id } = featured_image;
          return {
            uid: _id,
            name: featured_image.name || "",
            url: featured_image.path || "",
          };
        });
      }
      return [];
    },
    isEdit() {
      return !!Object.keys(this.service).length;
    },
    modalTitle() {
      return `${this.isEdit ? "Edit" : "Add"} Food Or Drink`;
    },
  },
  watch: {
    showModal: {
      handler(show) {
        this.visible = show;
      },
      immediate: true,
    },
    defaultService: {
      handler(service) {
        this.service = service;
      },
      immediate: true,
    },
    visible(show) {
      this.$emit("closeModal", show);
    },
    featuredImageData: {
      handler(value) {
        if (value.length > 0) {
          this.fileList.push(...value);
        }
      },
      deep: true,
      immediate: false,
    },
  },
  mounted() {
    if (this.isEdit) {
      this.$nextTick(() => {
        const { title, description, price } = this.defaultService;
        this.form.setFieldsValue({
          title,
          description,
          price,
        });
      });
      this.service = cloneDeep(this.defaultService);
    }
  },
  methods: {
    ...mapActions("nourishment", {
      deleteFeaturedImage: DELETE_FOOD_AND_BEVERAGES_SERVICE_FEATURED_MEDIA,
    }),
    beforeFeaturedImageUpload(file) {
      return false;
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (err) {
          this.$notification.error({
            message: "Please correct form errors.",
          });
          return false;
        }

        this.saveOrUpdateService(values);
      });
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handleChange(info) {
      try {
        const file = info.file;
        file.url = await this.getBase64(file);

        if (this.fileList.length < 3) {
          this.fileList.push(info.file);
          this.featuredImages.push(info.file);
        }
      } catch (error) {
        //
      }
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    async saveOrUpdateService(values) {
      try {
        this.loading = true;

        const formData = new FormData();
        const { _id } = this.service;

        const update_payload = {
          price_type: "fixed",
          ...values,
        };

        if (_id) {
          update_payload._id = _id;
        }

        Object.keys(update_payload).forEach((key) => {
          formData.append(key, update_payload[key]);
        });

        //then add featued images
        if (this.featuredImages.length > 0) {
          this.featuredImages.forEach((featured_image) => {
            formData.append("featured", featured_image);
          });
        }

        await this.updateProfileService(formData);

        this.visible = false;
      } catch (error) {
        console.log("error", error.message);
        this.$notification.error({ message: "Error adding entity user" });
      } finally {
        this.loading = false;
      }
    },
    async handleRemove(file) {
      try {
        this.loading = true;

        if (file.uid && validator.isMongoId(file.uid)) {
          await this.deleteFeaturedImage({
            service_id: this.service._id,
            featured_id: file.uid,
          });
        }

        const deletingFileIndexFromFileList = this.fileList
          .map((file) => file.uid)
          .indexOf(file.uid);

        if (deletingFileIndexFromFileList > -1) {
          this.fileList.splice(deletingFileIndexFromFileList, 1);
        }

        const deletingFileIndexFromFeaturedImages = this.featuredImages
          .map((file) => file.uid)
          .indexOf(file.uid);

        if (deletingFileIndexFromFeaturedImages > -1) {
          this.featuredImages.splice(deletingFileIndexFromFeaturedImages, 1);
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
